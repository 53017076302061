@font-face {
    font-family: 'work_sans';
    src: url('../assets/default/fonts/worksans-bold-webfont.woff2') format('woff2'),
        url('../assets/default/fonts/worksans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans';
    src: url('../assets/default/fonts/worksans-semibold-webfont.woff2') format('woff2'),
        url('../assets/default/fonts/worksans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans';
    src: url('../assets/default/fonts/worksans-medium-webfont.woff2') format('woff2'),
        url('../assets/default/fonts/worksans-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans';
    src: url('../assets/default/fonts/worksans-regular-webfont.woff2') format('woff2'),
        url('../assets/default/fonts/worksans-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
