@import 'brand-colors';

// Variable overrides for the Bryant branded site

$primaryExtraLight: map-get($primaryExtraLightBranded, 'carrier');
$primaryLight: map-get($primaryLightBranded, 'carrier');
$primaryLightTint1: map-get($primaryLightTint1Branded, 'carrier');
$primaryLightTint2: map-get($primaryLightTint2Branded, 'carrier');
$primaryLightTint3: map-get($primaryLightTint3Branded, 'carrier');
$primaryLightTint4: map-get($primaryLightTint4Branded, 'carrier');
$primary: map-get($primaryBranded, 'carrier');
$primaryTint: map-get($primaryTintBranded, 'carrier');
$primaryTransparent: map-get($primaryTransparentBranded, 'carrier');
$primaryDark: map-get($primaryDarkBranded, 'carrier');
$primaryExtraDark: map-get($primaryExtraDarkBranded, 'carrier');
$secondaryLight: map-get($secondaryLightBranded, 'carrier');
$secondaryDark: map-get($secondaryDarkBranded, 'carrier');
$accent: map-get($accentBranded, 'carrier');

$brand: map-get($brandBranded, 'carrier');
$highlight: map-get($highlightBranded, 'carrier');
$illustrations: map-get($illustrationsBranded, 'carrier');
$heroGradientStart: map-get($heroGradientStartBranded, 'carrier');
$heroGradientEnd: map-get($heroGradientEndBranded, 'carrier');
$lightPrimary: map-get($lightPrimaryBranded, 'carrier');
$mediumPrimary: map-get($mediumPrimaryBranded, 'carrier');
$topographicLinesDark: map-get($topographicLinesDarkBranded, 'carrier');
$topographicLinesLight: map-get($topographicLinesLightBranded, 'carrier');

$headerSearchBar: $primaryLightTint3;
$headerSearchHover: $primaryLightTint4;
$linkColor: $primary;
$linkHover: $primaryLight;

$defaultButton: (
    'color': $primaryBranded,
    'background': $primaryLightTint1Branded,
    'hoverColor': $primaryExtraDarkBranded,
    'hoverBackground': $primaryLightTint2Branded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $primaryBranded,
    'focusBackground': $primaryLightTint1Branded,
    'focusOutline': $primaryBranded
);

$primaryButton: (
    'color': $white,
    'background': $primaryBranded,
    'hoverColor': $white,
    'hoverBackground': $primaryExtraDarkBranded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $white,
    'focusBackground': $primaryExtraDarkBranded,
    'focusOutline': $primaryBranded
);

$secondaryButton: (
    'color': $primaryBranded,
    'background': $white,
    'hoverColor': $primaryExtraDarkBranded,
    'hoverBackground': $white,
    'hoverIcon': $primaryExtraDarkBranded,
    'focusColor': $primaryBranded,
    'focusBackground': $white,
    'focusOutline': $primaryBranded
);

$tertiaryButton: (
    'color': $primaryExtraLightBranded,
    'background': $primaryLightTint3Branded,
    'hoverColor': $white,
    'hoverBackground': $primaryLightTint4Branded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $primaryExtraLightBranded,
    'focusBackground': $primaryLightTint3Branded,
    'focusOutline': $accentBranded,
);
