/* You can add global styles to this file, and also import other style files */

@import '~normalize.css/normalize.css';
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
@import '../common/scss/fonts';
@import '../common/scss/variables';
@import '../common/scss/global';
@import '../common/scss/library';

.hvac-state-modal-open,
.hvac-slide-out-visible {
    div[id^='appzi'] {
        visibility: none;
        right: auto;
        left: -100rem;
    }
}
