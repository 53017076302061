.hvac-h1 {
    @include hvac-h1;
}

.hvac-h2 {
    @include hvac-h2;
}

.hvac-h3 {
    @include hvac-h3;
}

.hvac-h4 {
    @include hvac-h4;
}

.hvac-h5 {
    @include hvac-h5;
}

.hvac-h6 {
    @include hvac-h6;
}

.hvac-p-lg {
    @include hvac-p-lg;
}

.hvac-p {
    @include hvac-p;
}

.hvac-p-sm {
    @include hvac-p-sm;
}

.hvac-p-xs {
    @include hvac-p-xs;
}

.hvac-label {
    @include hvac-label;
}

.hvac-label-sm {
    @include hvac-label-sm;
}

.hvac-subtext {
    @include hvac-subtext;
}

.hvac-subhead {
    @include hvac-subhead;
}

.hvac-text-link {
    @include hvac-text-link;
}

.hvac-text-link-sm {
    @extend .hvac-text-link;

    font-size: 1.2rem;
}

.hvac-fw-rg {
    font-weight: $font-weight-regular;
}

.hvac-fw-md {
    font-weight: $font-weight-medium;
}

.hvac-fw-sb {
    font-weight: $font-weight-semibold;
}

.hvac-fw-bd {
    font-weight: $font-weight-bold;
}
