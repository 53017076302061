// --------------------------------------------------
// Grid
// --------------------------------------------------
$grid-prefix: 'hvac-';
$grid-breakpoints: (
    extra-small: (
        name: xs,
        columns: 12,
        gutter: 16px,
        min-width: 0
    ),
    small: (
        name: sm,
        columns: 12,
        gutter: 16px,
        min-width: 36em
    ),
    medium: (
        name: md,
        columns: 12,
        gutter: 40px,
        min-width: 48em
    ),
    large: (
        name: lg,
        columns: 12,
        gutter: 40px,
        min-width: 64em
    ),
    extra-large: (
        name: xl,
        columns: 12,
        gutter: 40px,
        min-width: 90em
    )
);

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }

    @return $map;
}

// --------------------------------------------------
// Colors
// --------------------------------------------------
$white: #FFF;
$black: #000;
$black-700: #231F20;

$blue-500: #036;
$blue-400: #004E80;
$blue-350: #006FDE;
$blue-300: #0079AD;
$blue-200: #1971FF;

$turqouise-600: #00B5A2;
$turqouise-500: #009DA3;
$turqouise-400: #99D8DA;
$turqouise-300: #CCEBED;
$turqouise-200: #E0F3F4;
$turqouise-100: #F0F9FA;

$gray-400: #637082;
$gray-300: #AFC2CC;
$gray-200: #E8E8E8;
$gray-100: #F8F8F8;

$lime-500: #CFFF70;

$orange: #FA0;

$red: #FF5433;
$red-200: #C32502;

$green: #36B336;
$lime-green: #DF9;

:root {
    --white: #{$white};
    --black: #{$black};
    --black-700: #{$black-700};
    --blue-500: #{$blue-500};
    --blue-400: #{$blue-400};
    --blue-350: #{$blue-350};
    --blue-300: #{$blue-300};
    --blue-200: #{$blue-200};
    --turqouise-600: #{$turqouise-600};
    --turqouise-500: #{$turqouise-500};
    --turqouise-400: #{$turqouise-400};
    --turqouise-300: #{$turqouise-300};
    --turqouise-200: #{$turqouise-200};
    --turqouise-100: #{$turqouise-100};
    --gray-400: #{$gray-400};
    --gray-300: #{$gray-300};
    --gray-200: #{$gray-200};
    --gray-100: #{$gray-100};
    --lime-500: #{$lime-500};
    --orange: #{$orange};
    --red: #{$red};
    --red-200: #{$red-200};
    --green: #{$green};
    --lime-green: #{$lime-green};
}

// --------------------------------------------------
// Usage
// --------------------------------------------------
$info: $blue-200;
$success: $green;
$warning: $orange;
$error: $red;

$cool: $blue-350;
$heat: $red-200;

$carrier-brand-badge: #1891F6;
$bryant-brand-badge: #FF2E41;
$payne-brand-badge: #59B300;

$primaryExtraLight: $turqouise-600;
$primaryLight: $turqouise-500;
$primaryLightTint1: rgba($primaryLight, 0.06);
$primaryLightTint2: rgba($primaryLight, 0.12);
$primaryLightTint3: rgba($primaryLight, 0.2);
$primaryLightTint4: rgba($primaryLight, 0.4);
$primary: $blue-300;
$primaryTint: rgba($primary, 0.2);
$primaryTransparent: rgba($primary, 0);
$primaryDark: $blue-400;
$primaryExtraDark: $blue-500;
$secondaryLight: $gray-200;
$secondaryDark: $gray-400;
$accent: $lime-500;
$tertiaryLight: $turqouise-100;
$headerSearchBar: rgba($primaryExtraLight, 0.2);
$headerSearchHover: rgba($primaryExtraLight, 0.5);
$linkColor: $primary;
$linkHover: $primaryLight;

$illustrations: #888;
$heroGradientStart: #00BEFF;
$heroGradientEnd: #007BFF;
$topographicLinesDark: #111;
$topographicLinesLight: #F2F2F2;
$bookmarkButtonColor: rgb(0, 157, 163);

$brand: $black;
$highlight: $black;
$lightPrimary: $gray-300;
$mediumPrimary: $gray-400;

$focusOutline: rgba(0, 103, 244, 1); // Chrome Default;
$defaultFocusStyle: $focusOutline solid 2px;
$darkFocusStyle: $black solid 2px;

$border-color: $gray-200;
$border-color-light: $gray-100;
$border: 1px solid $border-color;
$border-light: 1px solid $border-color-light;
$border-radius-100: 4px;
$shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
$shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);

// --------------------------------------------------
// Font Variables
// --------------------------------------------------
$default-font: 'work_sans', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// --------------------------------------------------
// Media Query Variables
// --------------------------------------------------
$width-min-mobile: 375;
$width-min-tablet: 768;
$width-min-hamburger-menu: 912;
$width-min-desktop: 1024;
$width-min-lg-desktop: 1440;

$max-width: 1280px;

$mq-min-mobile: '(min-width: #{$width-min-mobile}px)';
$mq-min-tablet: '(min-width: #{$width-min-tablet}px)';
$mq-min-desktop: '(min-width: #{$width-min-desktop}px)';
$mq-min-lg-desktop: '(min-width: #{$width-min-lg-desktop}px)';

$mq-min-hamburger-menu: '(min-width: #{$width-min-hamburger-menu}px)';

// --------------------------------------------------
// z-indexes
// --------------------------------------------------
$navigation: 10;
$breadcrumbs: 1;
$headerNavigation: 80;
$header: 100;
$modal: 150;
$chatbot: 190;
$overlay: 200;
$login-panel-overlay: 250;
$login-panel: 260;
$ie-banner: 300;
$suggest-window: 75;
$recent-activity-panel: 270;
$appzi-z-index: 2147480000;
$above-appzi-z-index: 2147480001;
$hamburger-menu: 12;
$aboveSearchBar: 1000;

// --------------------------------------------------
// Spacing
// --------------------------------------------------
$padding: 8px;
$rhythm: 8px;

// --------------------------------------------------
// Sizing
// --------------------------------------------------
$max-width-desktop: #{$width-min-desktop}px;

// --------------------------------------------------
// Shadows
// --------------------------------------------------
$content-box-shadow:  0 3.2rem 6.4rem rgba(0, 0, 0, 0.3);

// --------------------------------------------------
// Buttons
// --------------------------------------------------
$defaultButton: (
    'color': $primary,
    'background': $primaryLightTint1,
    'hoverColor': $primaryExtraDark,
    'hoverBackground': $primaryLightTint2,
    'hoverIcon': $primaryLight,
    'focusColor': $primary,
    'focusBackground': $primaryLightTint1,
    'focusOutline': $primary
);

$primaryButton: (
    'color':$white,
    'background':$primary,
    'hoverColor':$white,
    'hoverBackground':$primaryExtraDark,
    'hoverIcon':$primaryLight,
    'focusColor':$white,
    'focusBackground':$primaryExtraDark,
    'focusOutline':$primary
);

$secondaryButton: (
    'color': $primary,
    'background': $white,
    'hoverColor': $primaryExtraDark,
    'hoverBackground': $white,
    'hoverIcon': $primaryExtraDark,
    'focusColor': $primary,
    'focusBackground': $white,
    'focusOutline': $primary
);

$tertiaryButton: (
    'color': $primaryExtraLight,
    'background': $primaryLightTint3,
    'hoverColor': $white,
    'hoverBackground': $primaryLightTint4,
    'hoverIcon': $primaryLight,
    'focusColor': $primaryExtraLight,
    'focusBackground': $primaryLightTint3,
    'focusOutline': $accent,
);

// --------------------------------------------------
// Event chip colors
// --------------------------------------------------

$chip-background-blue: #EBF2FF;
$chip-text-blue: #0C2DAF;

$chip-background-green: #EBFEEB;
$chip-text-green: #058034;

$chip-background-yellow: #FFF3D6;
$chip-text-maroon: #852100;

$chip-background-gray: #EDF0F3;

