@import 'variables';
@import 'mixins';

.hvac-button {
    @include hvac-button;

    &.hvac-button-dark {
        @include hvac-button-dark;
    }
}

.hvac-button-primary {
    @include hvac-button-primary;

    border: 0;

    &.hvac-button-dark {
        @include hvac-button-primary-dark;
    }
}

.hvac-button-secondary {
    @include hvac-button-secondary;

    &.hvac-button-dark {
        @include hvac-button-secondary-dark;
    }
}

.hvac-button-tertiary {
    @include hvac-button-tertiary;
}

.hvac-button-warning {
    @include hvac-button-warning;
}

.hvac-button-icon {
    @include hvac-button-icon;
}

.hvac-button-text {
    @include hvac-link;
}

.hvac-button-small {
    @include hvac-button-small;
}

.hvac-button-full {
    width: 100%;
}

.hvac-button-spinner {
    margin-left: rhythm(1.5);
    position: relative;

    &:after {
        content: ' ';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid;
        border-color: var(--white) transparent var(--white) transparent;
        animation: hvac-button-spinner-keyframes 1.2s linear infinite;
    }

    &-dark {
        &:after {
            border-color:
                var(--primaryDarkBranded) transparent
                var(--primaryDarkBranded) transparent;
        }
    }
}

@keyframes hvac-button-spinner-keyframes {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
