$primaryBranded: (
    'default': #0079AD,
    'carrier': #006FDE,
    'bryant': #DE0012,
    'totaline': #DA291C
);
$primaryExtraLightBranded: (
    'default': #00B5A2,
    'carrier': #3DA7FF,
    'bryant': #FF7373,
    'totaline': #FFF
);
$primaryLightBranded: (
    'default': #009DA3,
    'carrier': #1891F6,
    'bryant': #FF4242,
    'totaline': #6A6A6A
);
$primaryLightTint1Branded: (
    'default': rgba(map-get($primaryLightBranded, 'default'), 0.06),
    'carrier': rgba(map-get($primaryLightBranded, 'carrier'), 0.06),
    'bryant': rgba(map-get($primaryLightBranded, 'bryant'), 0.06),
    'totaline': rgba(map-get($primaryLightBranded, 'totaline'), 0.06)
);
$primaryLightTint2Branded: (
    'default': rgba(map-get($primaryLightBranded, 'default'), 0.12),
    'carrier': rgba(map-get($primaryLightBranded, 'carrier'), 0.12),
    'bryant': rgba(map-get($primaryLightBranded, 'bryant'), 0.12),
    'totaline': rgba(map-get($primaryLightBranded, 'totaline'), 0.12)
);
$primaryLightTint3Branded: (
    'default': rgba(map-get($primaryLightBranded, 'default'), 0.2),
    'carrier': rgba(map-get($primaryLightBranded, 'carrier'), 0.2),
    'bryant': rgba(map-get($primaryLightBranded, 'bryant'), 0.2),
    'totaline': rgba(map-get($primaryLightBranded, 'totaline'), 0.2)
);
$primaryLightTint4Branded: (
    'default': rgba(map-get($primaryLightBranded, 'default'), 0.4),
    'carrier': rgba(map-get($primaryLightBranded, 'carrier'), 0.4),
    'bryant': rgba(map-get($primaryLightBranded, 'bryant'), 0.4),
    'totaline': rgba(map-get($primaryLightBranded, 'totaline'), 0.4)
);
$primaryTintBranded: (
    'default': rgba(map-get($primaryBranded, 'default'), 0.2),
    'carrier': rgba(map-get($primaryBranded, 'carrier'), 0.2),
    'bryant': rgba(map-get($primaryBranded, 'bryant'), 0.2),
    'totaline': rgba(map-get($primaryBranded, 'totaline'), 0.2)
);
$primaryTransparentBranded: (
    'default': rgba(map-get($primaryBranded, 'default'), 0),
    'carrier': rgba(map-get($primaryBranded, 'carrier'), 0),
    'bryant': rgba(map-get($primaryBranded, 'bryant'), 0),
    'totaline': rgba(map-get($primaryBranded, 'totaline'), 0)
);
$primaryDarkBranded: (
    'default': #004E80,
    'carrier': #0041B3,
    'bryant': #9E001A,
    'totaline': #231F20
);
$primaryExtraDarkBranded: (
    'default': #036,
    'carrier': #152C73,
    'bryant': #80000B,
    'totaline': #231F20
);
$secondaryLightBranded: (
    'default': #AFC2CC,
    'carrier': #BAC0D0,
    'bryant': #D0BABC,
    'totaline': #6A6A6A
);
$secondaryDarkBranded: (
    'default': #637082,
    'carrier': #617080,
    'bryant': #806164,
    'totaline': #6A6A6A
);
$accentBranded: (
    'default': #DF9,
    'carrier': #C9ECA3,
    'bryant': #31F5F5,
    'totaline': #FFF
);
$illustrationsBranded: (
    'default': #274D6D,
    'carrier': #274D6D,
    'bryant': #1B5656
);
$illustrationsPrivateBranded: (
    'default': #888,
    'carrier': #274D6D,
    'bryant': #1B5656
);
$heroGradientStartBranded: (
    'default': #0079AD,
    'carrier': #00BEFF,
    'bryant': #DC4966,
    'totaline': map-get($primaryBranded, 'totaline')
);
$heroGradientEndBranded: (
    'default': #00B5A2,
    'carrier': #007BFF,
    'bryant': #ED1B2E,
    'totaline': map-get($primaryBranded, 'totaline')
);
$topographicLinesDarkBranded: (
    'default': #036,
    'carrier': #1D3B54,
    'bryant': #4B2528,
    'totaline': rgba(#FFF, 0.5)
);
$topographicLinesLightBranded: (
    'default': rgba(map-get($accentBranded, 'default'), 0.6),
    'carrier': #75C0FF,
    'bryant': #FF5867,
    'totaline': rgba(#FFF, 0.5)
);
$topographicLinesExtraLightBranded: (
    'default': rgba(map-get($accentBranded, 'default'), 0.6),
    'carrier': rgba(map-get($accentBranded, 'carrier'), 0.6),
    'bryant': rgba(map-get($accentBranded, 'bryant'), 0.6)
);
$brandBranded: (
    'default': #0079AD,
    'carrier': #152C73,
    'bryant': #73151D,
    'totaline': #DA291C
);
$highlightBranded: (
    'default': #00B5A2,
    'carrier': #0078DC,
    'bryant': #ED1B2E
);
$lightPrimaryBranded: (
    'default': #AFC2CC,
    'carrier': #BAC0D0,
    'bryant': #D0BABC
);
$mediumPrimaryBranded: (
    'default': #637082,
    'carrier': #617080,
    'bryant': #806164
);
$primaryBrandedBoxGlow: (
    'default': 0 0 0.4rem 0.3rem map-get($primaryLightBranded, 'default'),
    'carrier': 0 0 0.4rem 0.3rem map-get($primaryLightBranded, 'carrier'),
    'bryant': 0 0 0.4rem 0.3rem map-get($primaryLightBranded, 'bryant'),
    'totaline': 0 0 0.4rem 0.3rem map-get($primaryBranded, 'totaline'),
);
$cardHeadOddBranded: (
    'default': #C2DBE0,
    'carrier': #ACD3FD,
    'bryant': #FFB7B5
);

$cardBodyOddBranded: (
    'default': #EBFBFE,
    'carrier': #EBF1F9,
    'bryant': #FFE9E8
);
$cardHeadEvenBranded: (
    'default': #DEEDEE,
    'carrier': #D6E9FE,
    'bryant': #FF7373
);

$cardBodyEvenBranded: (
    'default': #F8FBFB,
    'carrier': #F3F8FF,
    'bryant': #FBF0F0
);

$progressbarBranded:(
    'default': #D7E6E9,
    'carrier': #BFCFE6,
    'bryant':  #D0BABC
);

$progressbarValueBranded: (
    'default': 'linear-gradient(-45deg, #009DA3 0%, #6CC2C6 100%)',
    'carrier': 'linear-gradient(-45deg, #006FDE 0%, #46A2FF 100%)',
    'bryant': 'linear-gradient(-45deg, #DE0012 0%, #FF7D88 100%)'
);
// Custom CSS Property implementation (Required to update colors in ViewEncapsulated components)

:root,
.hvac-default-theme {
    --primaryBranded: #{map-get($primaryBranded, 'default')};
    --primaryExtraLightBranded: #{map-get($primaryExtraLightBranded, 'default')};
    --primaryLightBranded: #{map-get($primaryLightBranded, 'default')};
    --primaryLightTint1Branded: #{map-get($primaryLightTint1Branded, 'default')};
    --primaryLightTint2Branded: #{map-get($primaryLightTint2Branded, 'default')};
    --primaryLightTint3Branded: #{map-get($primaryLightTint3Branded, 'default')};
    --primaryLightTint4Branded: #{map-get($primaryLightTint4Branded, 'default')};
    --primaryTintBranded: #{map-get($primaryTintBranded, 'default')};
    --primaryTransparentBranded: #{map-get($primaryTransparentBranded, 'default')};
    --primaryDarkBranded: #{map-get($primaryDarkBranded, 'default')};
    --primaryExtraDarkBranded: #{map-get($primaryExtraDarkBranded, 'default')};
    --secondaryLightBranded: #{map-get($secondaryLightBranded, 'default')};
    --secondaryDarkBranded: #{map-get($secondaryDarkBranded, 'default')};
    --accentBranded: #{map-get($accentBranded, 'default')};
    --illustrationsBranded: #{map-get($illustrationsBranded, 'default')};
    --illustrationsPrivateBranded: #{map-get($illustrationsPrivateBranded, 'default')};
    --heroGradientStartBranded: #{map-get($heroGradientStartBranded, 'default')};
    --heroGradientEndBranded: #{map-get($heroGradientEndBranded, 'default')};
    --topographicLinesDarkBranded: #{map-get($topographicLinesDarkBranded, 'default')};
    --topographicLinesLightBranded: #{map-get($topographicLinesLightBranded, 'default')};
    --topographicLinesExtraLightBranded: #{map-get($topographicLinesExtraLightBranded, 'default')};
    --brandBranded: #{map-get($brandBranded, 'default')};
    --highlightBranded: #{map-get($highlightBranded, 'default')};
    --lightPrimaryBranded: #{map-get($lightPrimaryBranded, 'default')};
    --mediumPrimaryBranded: #{map-get($mediumPrimaryBranded, 'default')};
    --primaryBrandedBoxGlow: #{map-get($primaryBrandedBoxGlow, 'default')};
}

.local.carrier,
.dev.carrier,
.staging.carrier,
.production.carrier,
.test.carrier {
    --primaryBranded: #{map-get($primaryBranded, 'carrier')};
    --primaryExtraLightBranded: #{map-get($primaryExtraLightBranded, 'carrier')};
    --primaryLightBranded: #{map-get($primaryLightBranded, 'carrier')};
    --primaryLightTint1Branded: #{map-get($primaryLightTint1Branded, 'carrier')};
    --primaryLightTint2Branded: #{map-get($primaryLightTint2Branded, 'carrier')};
    --primaryLightTint3Branded: #{map-get($primaryLightTint3Branded, 'carrier')};
    --primaryLightTint4Branded: #{map-get($primaryLightTint4Branded, 'carrier')};
    --primaryTintBranded: #{map-get($primaryTintBranded, 'carrier')};
    --primaryTransparentBranded: #{map-get($primaryTransparentBranded, 'carrier')};
    --primaryDarkBranded: #{map-get($primaryDarkBranded, 'carrier')};
    --primaryExtraDarkBranded: #{map-get($primaryExtraDarkBranded, 'carrier')};
    --secondaryLightBranded: #{map-get($secondaryLightBranded, 'carrier')};
    --secondaryDarkBranded: #{map-get($secondaryDarkBranded, 'carrier')};
    --accentBranded: #{map-get($accentBranded, 'carrier')};
    --illustrationsBranded: #{map-get($illustrationsBranded, 'carrier')};
    --illustrationsPrivateBranded: #{map-get($illustrationsPrivateBranded, 'carrier')};
    --heroGradientStartBranded: #{map-get($heroGradientStartBranded, 'carrier')};
    --heroGradientEndBranded: #{map-get($heroGradientEndBranded, 'carrier')};
    --topographicLinesDarkBranded: #{map-get($topographicLinesDarkBranded, 'carrier')};
    --topographicLinesLightBranded: #{map-get($topographicLinesLightBranded, 'carrier')};
    --topographicLinesExtraLightBranded: #{map-get($topographicLinesExtraLightBranded, 'carrier')};
    --brandBranded: #{map-get($brandBranded, 'carrier')};
    --highlightBranded: #{map-get($highlightBranded, 'carrier')};
    --lightPrimaryBranded: #{map-get($lightPrimaryBranded, 'carrier')};
    --mediumPrimaryBranded: #{map-get($mediumPrimaryBranded, 'carrier')};
    --primaryBrandedBoxGlow: #{map-get($primaryBrandedBoxGlow, 'carrier')};
}

.local.bryant,
.dev.bryant,
.staging.bryant,
.production.bryant,
.test.bryant {
    --primaryBranded: #{map-get($primaryBranded, 'bryant')};
    --primaryExtraLightBranded: #{map-get($primaryExtraLightBranded, 'bryant')};
    --primaryLightBranded: #{map-get($primaryLightBranded, 'bryant')};
    --primaryLightTint1Branded: #{map-get($primaryLightTint1Branded, 'bryant')};
    --primaryLightTint2Branded: #{map-get($primaryLightTint2Branded, 'bryant')};
    --primaryLightTint3Branded: #{map-get($primaryLightTint3Branded, 'bryant')};
    --primaryLightTint4Branded: #{map-get($primaryLightTint4Branded, 'bryant')};
    --primaryTintBranded: #{map-get($primaryTintBranded, 'bryant')};
    --primaryTransparentBranded: #{map-get($primaryTransparentBranded, 'bryant')};
    --primaryDarkBranded: #{map-get($primaryDarkBranded, 'bryant')};
    --primaryExtraDarkBranded: #{map-get($primaryExtraDarkBranded, 'bryant')};
    --secondaryLightBranded: #{map-get($secondaryLightBranded, 'bryant')};
    --secondaryDarkBranded: #{map-get($secondaryDarkBranded, 'bryant')};
    --accentBranded: #{map-get($accentBranded, 'bryant')};
    --illustrationsBranded: #{map-get($illustrationsBranded, 'bryant')};
    --illustrationsPrivateBranded: #{map-get($illustrationsPrivateBranded, 'bryant')};
    --heroGradientStartBranded: #{map-get($heroGradientStartBranded, 'bryant')};
    --heroGradientEndBranded: #{map-get($heroGradientEndBranded, 'bryant')};
    --topographicLinesDarkBranded: #{map-get($topographicLinesDarkBranded, 'bryant')};
    --topographicLinesLightBranded: #{map-get($topographicLinesLightBranded, 'bryant')};
    --topographicLinesExtraLightBranded: #{map-get($topographicLinesExtraLightBranded, 'bryant')};
    --brandBranded: #{map-get($brandBranded, 'bryant')};
    --highlightBranded: #{map-get($highlightBranded, 'bryant')};
    --lightPrimaryBranded: #{map-get($lightPrimaryBranded, 'bryant')};
    --mediumPrimaryBranded: #{map-get($mediumPrimaryBranded, 'bryant')};
    --primaryBrandedBoxGlow: #{map-get($primaryBrandedBoxGlow, 'bryant')};
}

.local.totaline,
.dev.totaline,
.staging.totaline,
.production.totaline,
.test.totaline {
    --primaryBranded: #{map-get($primaryBranded, 'totaline')};
    --primaryExtraLightBranded: #{map-get($primaryExtraLightBranded, 'totaline')};
    --primaryLightBranded: #{map-get($primaryLightBranded, 'totaline')};
    --primaryLightTint1Branded: #{map-get($primaryLightTint1Branded, 'totaline')};
    --primaryLightTint2Branded: #{map-get($primaryLightTint2Branded, 'totaline')};
    --primaryLightTint3Branded: #{map-get($primaryLightTint3Branded, 'totaline')};
    --primaryLightTint4Branded: #{map-get($primaryLightTint4Branded, 'totaline')};
    --primaryTintBranded: #{map-get($primaryTintBranded, 'totaline')};
    --primaryTransparentBranded: #{map-get($primaryTransparentBranded, 'totaline')};
    --primaryDarkBranded: #{map-get($primaryDarkBranded, 'totaline')};
    --primaryExtraDarkBranded: #{map-get($primaryExtraDarkBranded, 'totaline')};
    --secondaryLightBranded: #{map-get($secondaryLightBranded, 'totaline')};
    --secondaryDarkBranded: #{map-get($secondaryDarkBranded, 'totaline')};
    --accentBranded: #{map-get($accentBranded, 'totaline')};
    --illustrationsBranded: #{map-get($illustrationsBranded, 'default')};
    --illustrationsPrivateBranded: #{map-get($illustrationsPrivateBranded, 'default')};
    --heroGradientStartBranded: #{map-get($heroGradientStartBranded, 'totaline')};
    --heroGradientEndBranded: #{map-get($heroGradientEndBranded, 'totaline')};
    --topographicLinesDarkBranded: #{map-get($topographicLinesDarkBranded, 'totaline')};
    --topographicLinesLightBranded: #{map-get($topographicLinesLightBranded, 'totaline')};
    --topographicLinesExtraLightBranded: #{map-get($topographicLinesExtraLightBranded, 'default')};
    --brandBranded: #{map-get($brandBranded, 'totaline')};
    --highlightBranded: #{map-get($highlightBranded, 'default')};
    --lightPrimaryBranded: #{map-get($lightPrimaryBranded, 'default')};
    --mediumPrimaryBranded: #{map-get($mediumPrimaryBranded, 'default')};
    --primaryBrandedBoxGlow: #{map-get($primaryBrandedBoxGlow, 'totaline')};
}
