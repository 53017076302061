@import 'variables';
@import 'mixins';


.hvac-link {
    @include hvac-link;

    &.hvac-link-underline {
        @include hvac-link-underline;
    }
}

.hvac-link-primary {
    @include hvac-link-primary;

    &.hvac-link-dark {
        @include hvac-link-primary-dark;
    }
}

.hvac-link-alt {
    @include hvac-link-alt;

    .hvac-link-dark {
        @include hvac-link-alt-dark;
    }
}

