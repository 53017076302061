@import 'variables';
@import 'mixins';
@import 'functions';
@import 'typography';
@import '../../../node_modules/sass-flex-grid/index.scss';
@import 'forms';

html {
    box-sizing: border-box;
    scroll-behavior: initial;
}

body {
    background-color: $white;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
utc-root {
    min-height: 100%;
    height: 100%;
    min-width: 100%;
    width: 100%;
    font-family: $default-font;
    font-weight: $font-weight-regular;
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &[tabindex]:focus {
        outline: none;
    }
}

utc-root {
    display: block;

    p {
        font-size: 1.8rem;
        line-height: 2.6rem;
    }
}

button:focus,
a:focus,
form:focus,
[tabindex]:focus,
input:focus,
textarea:focus {
    @include utc-global-focus-style;
}

img {
    font-size: 1rem;
    line-height: 1;
}

section {
    display: block;
}

:host {
    display: block;
}

.hvac-accessible-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    top: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;
}

.hvac-homepage {
    display: block;
    box-sizing: border-box;
    min-width: 100%;
    overflow: hidden;
    padding-bottom: 100px;

    &.hvac-search {
        padding-bottom: 0;
    }
}

.hvac-homepage-content {
    position: relative;
}

.hvac-state-fixed {
    body {
        position: relative;
        overflow: hidden;
    }
}

.hvac-slide-out-visible {
    body {
        position: fixed;
        overflow: hidden;
    }
}

.hvac-hero-large-text {
    margin: 0 0 1rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: $font-weight-bold;
}

.hvac-hero-normal-text {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin: 0;
}

.hvac-placeholder-content {
    background: rgba($white, 0.4);
    border-radius: 40px;
}

.hvac-placeholder-content-dark {
    background: $gray-200;
}

.hvac-content-container {
    padding: 5rem 2.4rem 0;
    max-width: $max-width;
}

.hvac-invisible-submit {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    border: none;
    outline: none;
    opacity: 0;
    padding: 0;
}

.hvac-tooltip-heading {
    color: $white;
    margin: 0 0 8px 5px;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
}

.hvac-tooltip-description {
    color: $white;
    margin: 0 5px;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.hvac-divider {
    border-bottom: $border;
    margin-top: $rhythm * 3;
    margin-bottom: $rhythm * 3;
}

.hvac-hidden {
    display: none;
}

.hvac-large-only {
    display: none;
}

.hvac-fault-indicator {
    @include fault-indicator;
}

@for $i from 1 through 10 {
    .hvac-rhythm-#{$i} {
        display: block;
        margin-bottom: $rhythm * $i;
    }
}

@media #{$mq-min-desktop} {
    .hvac-hero-large-text {
        margin: 0;
        font-size: 4.8rem;
        line-height: 5.6rem;
    }

    .hvac-hero-normal-text {
        font-size: 3.2rem;
        line-height: 4.8rem;
    }

    .hvac-content-container {
        padding-top: 5rem;
        margin-left: auto;
        margin-right: auto;
    }

    .hvac-small-only {
        display: none;
    }

    .hvac-large-only {
        display: block;
    }
}

.hvac-hide-xs {
    display: none;
}

@include mq('small') {
    .hvac-show-sm {
        display: block;
    }

    .hvac-hide-sm {
        display: none;
    }
}

@include mq('medium') {
    .hvac-show-md {
        display: block;
    }

    .hvac-hide-md {
        display: none;
    }
}

@include mq('large') {
    .hvac-show-lg {
        display: block;
    }

    .hvac-hide-lg {
        display: none;
    }
}

@include mq('extra-large') {
    .hvac-show-xl {
        display: block;
    }

    .hvac-hide-xl {
        display: none;
    }
}
