.hvac-form-element {
    position: relative;
}

.hvac-input-action-wrap {
    display: flex;
    position: absolute;
    right: $rhythm;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    .hvac-button-icon {
        height: 24px;
        width: 24px;
        margin-left: 2px;
    }

    .hvac-button-icon:hover {
        background: $turqouise-500;
    }

    .hvac-clear-button {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
        line-height: 1.6rem;
        min-height: auto;
    }
}
