@import 'variables';
@import 'brand-colors';
@import 'mixins';

.hvac-shadow-button {
    transition: box-shadow 0.2s ease-out;
    background-color: $white;
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $black;
    padding: 1.6rem 7.4rem;
    border-radius: 50px;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: $white;
        box-shadow: 0 10px 16px rgba(0, 0, 0, 0.12);
        text-decoration: none;
        color: $black;
    }
}

.hvac-primary-button {
    transition: background 0.2s ease-out;
    font-weight: $font-weight-medium;
    border: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $white;
    padding: 1rem 3rem;
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;

    @include branded('background-color', $primaryBranded);

    &:hover,
    &:active {
        text-decoration: none;
        color: $white;

        @include branded('background-color', $brandBranded);
    }
}

.hvac-icon-button {
    .hvac-icon-button-icon {
        display: inline-block;
    }

    svg {
        display: block;
        height: 100%;
        width: 100%;
    }

    &:hover svg path {
        color: $white;
    }
}

.hvac-text-button {
    background: transparent;
    border: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $black;
    font-weight: $font-weight-medium;
    padding: 5px;
    cursor: pointer;

    &.hvac-collapse-button {
        background: transparent;
        position: relative;
        display: block;
        padding: 15px 5px 15px 45px;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(0);
            width: 24px;
            height: 24px;
            background-image: url('../../assets/default/icons/chevron-down.svg');
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: center;

            .hvac-collapse-button-collapsed & {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &:focus {
            outline: $defaultFocusStyle;
        }
    }
}

.hvac-shadow-button-with-icon {
    @include utc-shadow-button-icon-base;

    &.hvac-shadow-button-text-centered {
        display: block;
        text-align: center;
        padding: 12px;
    }

    &.hvac-view-more-button {
        display: flex;
        min-width: 220px;

        &:after {
            transform: rotate(90deg);
        }
    }

    &.hvac-shadow-button-with-icon-play {
        &:after {
            background-image: url('../../assets/default/icons/play.svg');
        }
    }
}

.hvac-adaptive-button {
    background-color: transparent;
    position: relative;
    box-shadow: none;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    @include branded('color', $mediumPrimaryBranded);

    .hvac-adaptive-button-text {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

    svg {
        display: block;
        width: 16px;
    }

    &:hover,
    &:focus {
        background: $gray-100;
    }
}

.hvac-tab-component-button,
.hvac-product-variant-button {
    position: relative;
    border-radius: 24px;
    height: 40px;
    padding: 14px 45px 14px 15px;
    background: transparent;
    border: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: $font-weight-medium;
    width: 100%;
    justify-content: space-between;

    @include branded('color', $mediumPrimaryBranded);

    &:focus {
        outline: $defaultFocusStyle;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -14px;
        right: 6px;
        width: 28px;
        height: 28px;
        border-radius: 56px;
        background-image: url('../../assets/default/icons/arrow-white.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
        display: none;

        @include branded('background-color', $highlightBranded);
    }
}

@each $brand in $brands {
    .#{$brand} {
        .hvac-tab-component-button,
        .hvac-product-variant-button {
            background: transparent;
        }

        .hvac-button-active {
            .hvac-tab-component-button,
            .hvac-product-variant-button {
                color: $white;
            }
        }
    }
}

.hvac-tab-component-button {
    width: 100%;
    align-items: flex-start;
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.18);


    &:hover {
        @include branded('background', $primaryBranded);

        color: $white;
    }
}

.hvac-button-active {
    .hvac-tab-component-button,
    .hvac-product-variant-button {
        color: $white;

        @include branded('background', $primaryBranded);

        &:after {
            display: block;
        }
    }
}

.hvac-light-button-link {
    transition: box-shadow 0.2s ease-out, color 0.2s ease-out;

    @include branded('background-color', $white);
    @include branded('color', $primaryBranded);

    &:hover,
    &:focus {
        box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.08);

        @include branded('background-color', $white);
        @include branded('color', $primaryExtraDarkBranded);
    }
}

@media #{$mq-min-tablet} {
    .hvac-adaptive-button {
        @include utc-shadow-button-icon-base;
        @include branded('color', $white);

        &:after {
            background-image: none;
        }

        .hvac-adaptive-button-icon {
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            right: 26px;
        }

        .hvac-adaptive-button-text {
            color: $black;
            width: 100%;
            height: 20px;
            opacity: 1;
        }
    }
}

.hvac-header-search .hvac-button:hover utc-svg {
    background: none;
    color: #637082;
}
