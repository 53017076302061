@import 'brand-colors';
@import 'mixins';
@import 'variables';

$boxShadowBranded: (
    'default': 0 1.2rem 2.4rem map-get($primaryLightTint3Branded, 'default'),
    'carrier': 0 1.2rem 2.4rem map-get($primaryLightTint3Branded, 'carrier'),
    'bryant': 0 1.2rem 2.4rem map-get($primaryLightTint3Branded, 'bryant'),
);

/* stylelint-disable max-line-length */

$boxShadowHoverBranded: (
    'default': "0 2.4rem 2.4rem map-get($primaryLightTint3Branded, 'default'), 0 -0.8rem 1.2rem map-get($primaryLightTint1Branded, 'default')",
    'carrier': "0 2.4rem 2.4rem map-get($primaryLightTint3Branded, 'carrier'), 0 -0.8rem 1.2rem map-get($primaryLightTint1Branded, 'carrier')",
    'bryant': "0 2.4rem 2.4rem map-get($primaryLightTint3Branded, 'bryant'), 0 -0.8rem 1.2rem map-get($primaryLightTint1Branded, 'bryant')"
);

/* stylelint-enable max-line-length */

.hvac-card {
    background: $white;
    border-radius: 0.8rem;
    text-align: center;
    padding: 2.4rem 3.2rem 3.4rem;

    @include branded('box-shadow', $boxShadowBranded);

    &.hvac-card-interactive {
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
            transform: translateY(-0.8rem);

            @include branded('box-shadow', $boxShadowHoverBranded);
        }
    }
}

@media #{$mq-min-tablet} {
    .hvac-card {
        padding: 6.4rem 6.6rem 3.2rem;

        &.hvac-card-interactive {
            &:hover {
                transform: translateY(-1.6rem);
            }
        }
    }
}
