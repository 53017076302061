@use 'sass:math';
@use 'sass:list';
@use 'sass:map';
@import 'variables';
@import 'brand-colors';

$brands: 'carrier', 'bryant', 'totaline';

/* stylelint-disable at-rule-name-newline-after */

@function brandvar($styles, $brand) {
    @if type-of($styles) == 'map' {
        $prop: map-get($styles, $brand);

        @if type-of($prop) == 'string' {
            $prop: unquote($prop);
        }

        @return $prop;
    }

    @else {
        @return $styles;
    }
}

@mixin branded($property, $brandStyles) {
    $defaultBrand: brandvar($brandStyles, 'default');

    #{$property}: $defaultBrand;

    @each $brand in $brands {
        .#{$brand} & {
            #{$property}: brandvar($brandStyles, $brand);
        }
    }

    // Allows explicit overriding of the branded colors with default theme

    .hvac-default-theme & {
        #{$property}: $defaultBrand;
    }
}

@mixin dotted-focus-style {
    outline: var(--primaryLightBranded) dotted 0.2rem;
    outline-offset: 0.2rem;
}

@mixin utc-global-focus-style {
    outline: $defaultFocusStyle;
    outline-offset: 1px;
}

@mixin utc-shadow-button-icon-base {
    position: relative;
    align-items: center;
    background-color: $white;
    border-radius: 48px;
    box-shadow: 0 8px 16px rgba($black, 0.1);
    color: $black;
    display: inline-flex;
    font-size: 1.8rem;
    font-weight: $font-weight-medium;
    padding: 12px 62px 12px 24px;
    height: 64px;
    text-decoration: none;
    transition: box-shadow 0.2s ease-out;
    width: auto;
    border: none;
    text-align: left;
    cursor: pointer;

    &:focus {
        outline: $defaultFocusStyle;
    }

    &:after {
        transition: background-color 0.2s ease;
        content: '';
        position: absolute;
        top: 12px;
        right: 12px;
        width: 40px;
        height: 40px;
        padding: 14px;
        background: url('../assets/default/icons/arrow-white.svg') center / 1.2rem no-repeat;
        border-radius: 48px;

        @include branded('background-color', $primaryBranded);
    }

    &:hover {
        box-shadow: 0 16px 16px rgba($black, 0.18);

        &:after {
            @include branded('background-color', $brandBranded);
        }
    }
}

// Prefer using the button directive to apply these styles.
// Mixins provided in situations where directives cannot be used - CMS content or Okta widget.

@mixin hvac-button-colors($color,
$background,
$hoverColor,
$hoverBackground,
$hoverIcon,
$focusColor,
$focusBackground,
$focusOutline,
$hoverBorder: none,
) {
    cursor: pointer;
    transition: background 0.2s ease-out;

    @include branded('background', $background);
    @include branded('color', $color);

    utc-svg {
        transition: background 0.2s ease-out;
    }

    &:focus {
        outline-width: 0.2rem;
        outline-style: dotted;
        outline-offset: 0.2rem;

        @include branded('outline-color', $focusOutline);
        @include branded('background', $focusBackground);
        @include branded('color', $focusColor);
    }

    &:hover {
        @include branded('background', $hoverBackground);
        @include branded('color', $hoverColor);

        box-shadow: $hoverBorder;
    }
}

@mixin hvac-button-default {
    border-radius: 32px;
    font-size: 1.6rem;
    font-weight: $font-weight-medium;
    min-height: 4.8rem;
    line-height: 2rem;
    padding: $padding $padding * 4;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 250ms ease-in-out;
    text-decoration: none;

    &.hvac-button-has-icon {
        justify-content: space-between;
    }

    > span {
        flex: 1 auto;
        padding: 0 1.2rem;
    }

    .hvac-svg {
        align-items: center;
        border-radius: 1.6rem;
        display: flex;
        flex: 0 auto;
        height: 100%;
        justify-content: center;
        padding: 0.1rem;
        width: 3.2rem;
        position: relative;

        .hvac-button & {
            justify-content: space-between;
        }

        span {
            flex: 1 auto;
            line-height: 0;
            width: 100%;
        }

        svg {
            max-width: 1.2rem;
            width: 100%;
            height: auto;
        }
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;

        @include branded('background', $primaryLightTint1Branded);
        @include branded('color', $secondaryLightBranded);

        utc-svg {
            @include branded('background', transparent);
        }
    }
}

/* The order of this list is important as it reflects the order that
 * hvac-button-colors() is expecting the parameters */
$buttonProps: 'color', 'background', 'hoverColor', 'hoverBackground',
    'hoverIcon', 'focusColor', 'focusBackground', 'focusOutline', 'hoverBorder';

@function getbrandcolorslist($brandbuttonDefs) {
    $buttonParams: ();

    @each $param in $buttonProps {
        @if (map-has-key($brandbuttonDefs, $param)) {
            $buttonParams: list.append($buttonParams, map-get($brandbuttonDefs, $param));
        }
    }

    @return $buttonParams;
}

@mixin hvac-button {
    $defaultButtonColors: getbrandcolorslist($defaultButton);

    @include hvac-button-colors($defaultButtonColors...);
    @include hvac-button-default;
}

@mixin hvac-button-primary {
    $primaryButtonColors: getbrandcolorslist($primaryButton);

    @include hvac-button-colors($primaryButtonColors...);
    @include hvac-button-default;
}

@mixin hvac-button-warning {
    @include hvac-button-default;

    background-color: $error;
    color: $white;

    &:hover {
        color: $white;
        background-color: $red-200;
    }
}


@mixin hvac-button-icon {
    @include hvac-button;
    @include icon;

    padding: 0;
    border-radius: 100px;
    min-height: auto;

    .hvac-svg {
        right: 0;
        width: 3.6rem;
        padding: 0;
    }

    utc-svg {
        @include branded('background', $primaryLightTint1Branded);
        @include branded('color', $primaryBranded);
    }

    &:focus,
    &:hover {
        utc-svg {
            @include branded('background', $primaryLightBranded);
            @include branded('color', $white);
        }

        .hvac-svg {
            background: none;
        }
    }
}

@mixin hvac-button-small {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: math.div($padding, 2) $padding * 2;
    height: auto;
    min-height: auto;

    .hvac-svg {
        height: 2.4rem;
        width: 2.4rem;
        right: -1.2rem;
        padding: 0;
    }
}

@mixin hvac-button-secondary {
    $secondaryButtonColors: getbrandcolorslist($secondaryButton);

    @include hvac-button-colors($secondaryButtonColors...);
    @include hvac-button-default;

    &:hover,
    &:focus {
        box-shadow: $shadow;
    }
}

@mixin hvac-button-tertiary {
    $tertiaryButtonColors: getbrandcolorslist($tertiaryButton);

    @include hvac-button-colors($tertiaryButtonColors...);
    @include hvac-button-default;
}

@mixin hvac-link {
    color: $linkColor;
    text-decoration: none;
    font-weight: $font-weight-medium;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: $linkHover;
        text-decoration-color: $linkHover;
    }

    &:focus {
        outline: 0.2rem dotted $linkHover;
    }
}

@mixin hvac-link-primary {
    @include branded('color', $secondaryDarkBranded);
}

@mixin hvac-link-primary-dark {
    @include branded('color', $secondaryLightBranded);
}

@mixin hvac-link-alt {
    font-weight: $font-weight-bold;

    @include branded('color', $primaryExtraLightBranded);
}

@mixin hvac-link-alt-dark {
    font-weight: $font-weight-bold;

    @include branded('color', $primaryExtraLightBranded);
}

@mixin hvac-link-underline {
    text-decoration: underline;

    @include branded('text-decoration-color', $secondaryLightBranded);

    &:hover {
        @include branded('text-decoration-color', $accentBranded);
    }
}

@mixin hvac-checkbox-input {
    opacity: 0;
    outline: none;

    &:checked {
        & + .hvac-checkbox-input-text:before {
            @include branded('background', $primaryBranded);
            @include branded('border-color', $primaryBranded);
        }

        & + .hvac-checkbox-input-text:after {
            opacity: 1;
            transform: scale(1);
        }

        &:focus,
        &:hover {
            & + .hvac-checkbox-input-text:before {
                @include branded('background', $primaryBranded);
                @include branded('border-color', $primaryBranded);
            }
        }
    }

    &:disabled {
        & + .hvac-checkbox-input-text {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    &:focus,
    &:hover {
        & + .hvac-checkbox-input-text:before {
            background: $gray-100;
        }
    }

    &:focus {
        & + .hvac-checkbox-input-text:before {
            outline: $defaultFocusStyle;
        }
    }
}

@mixin hvac-checkbox-text {
    display: inline-block;
    pointer-events: none;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: $font-weight-medium;
    padding-left: 35px;
    padding-top: 4px;


    &:before {
        transition: background 0.2s ease, border 0.2s ease;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $gray-400;
        background: $white;
        border-radius: 2px;
    }

    &:after {
        transition: transform 0.3s ease, opacity 0.3s ease;
        content: '';
        display: block;
        opacity: 0;
        transform: scale(0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background-image: url('/assets/icons/check-white.svg');
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
    }
}

@mixin hvac-radio-button-input {
    opacity: 0;
    outline: none;

    &:checked {
        & + .hvac-radio-button-input-text:before {
            @include branded('background', $primaryBranded);
            @include branded('border-color', $primaryBranded);
        }

        & + .hvac-radio-button-input-text:after {
            opacity: 1;
            transform: scale(4);
        }

        &:focus,
        &:hover {
            & + .hvac-radio-button-input-text:before {
                @include branded('background', $primaryBranded);
                @include branded('border-color', $primaryBranded);
            }
        }
    }

    &:focus,
    &:hover {
        & + .hvac-radio-button-input-text:before {
            background: $gray-100;
        }
    }

    &:focus {
        & + .hvac-radio-button-input-text:before {
            outline: $defaultFocusStyle;
        }
    }
}

@mixin hvac-radio-button-text {
    display: inline-block;
    pointer-events: none;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: $font-weight-medium;
    padding-left: 35px;
    padding-top: 4px;


    &:before {
        transition: background 0.2s ease, border 0.2s ease;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $gray-400;
        background: $white;
        border-radius: 5rem;
    }

    &:after {
        transition: transform 0.3s ease, opacity 0.3s ease;
        content: '';
        display: block;
        opacity: 0;
        transform: scale(0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
    }
}

@mixin icon($width: 36px, $height: 36px, $iconWidth: 8px, $iconHeight: 8px) {
    align-items: center;
    border-radius: math.div($width, 2);
    display: flex;
    height: $height;
    justify-content: center;
    line-height: 0;
    width: $width;

    utc-svg {
        height: $iconHeight;
        width: $iconWidth;
    }
}

@mixin fault-indicator ($color: $red, $size: 1.2rem) {
    &:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        height: $size;
        width: $size;
        background-color: $color;
        margin-right: rhythm(1);
        @content;
    }
}

@mixin rounded-top($radius: $border-radius-100) {
    border-radius: $radius $radius 0 0;
}

@mixin rounded-bottom($radius: $border-radius-100) {
    border-radius: 0 0 $radius $radius;
}

@mixin rounded-left($radius: $border-radius-100) {
    border-radius: $radius 0 0 $radius;
}

@mixin rounded-right($radius: $border-radius-100) {
    border-radius: 0 $radius $radius 0;
}

// Typography
@mixin hvac-h1($color: $black) {
    margin-top: 0;
    margin-bottom: 0;
    color: $color;
    font-size: 4.8rem;
    line-height: 1.19;
    font-weight: $font-weight-bold;
}

@mixin hvac-h2($color: $black) {
    margin-top: 0;
    margin-bottom: 5px;
    color: $color;
    font-size: 3rem;
    line-height: 1.26;
    font-weight: $font-weight-bold;
}

@mixin hvac-h3($color: $black) {
    color: $color;
    font-size: 2.4rem;
    line-height: 1.16;
    font-weight: $font-weight-bold;
}

@mixin hvac-h4($color: $black) {
    color: $color;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: $font-weight-bold;
}

@mixin hvac-h5($color: $black) {
    color: $color;
    font-size: 1.4rem;
    line-height: 1.28;
    font-weight: $font-weight-bold;
}

@mixin hvac-h6($color: $primary) {
    color: $color;
    font-size: 1.4rem;
    line-height: 1.28;
    font-weight: $font-weight-medium;
}

@mixin hvac-p-lg($color: $secondaryDark, $font-weight: $font-weight-regular) {
    font-size: 3.2rem;
    line-height: 1.5;
    font-weight: $font-weight;
    color: $color;
}

@mixin hvac-p($color: $secondaryDark, $font-weight: $font-weight-regular) {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: $font-weight;
    color: $color;
}

@mixin hvac-p-sm($color: $secondaryDark, $font-weight: $font-weight-medium) {
    font-size: 1.4rem;
    line-height: 1.28;
    font-weight: $font-weight;
    color: $color;
}

@mixin hvac-p-xs($color: $secondaryDark, $font-weight: $font-weight-medium) {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: $font-weight;
    color: $color;
}

@mixin hvac-label($color: $black, $font-weight: $font-weight-medium) {
    font-size: 1.4rem;
    font-weight: $font-weight;
    line-height: 2rem;
    color: $color;
}

@mixin hvac-label-sm($color: $black, $font-weight: $font-weight-medium) {
    font-size: 1.2rem;
    font-weight: $font-weight;
    line-height: 1.33;
    color: $color;
}

@mixin hvac-subtext($color: $secondaryDark, $font-weight: $font-weight-medium) {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: $font-weight;
    color: $secondaryDark;
}

@mixin hvac-subhead($color: $black, $font-weight: $font-weight-medium) {
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    color: $color;
}

@mixin hvac-text-link {
    background: none;
    border: none;
    text-decoration: none;
    padding-left: 0;
    padding-right: 0;
    font-weight: $font-weight-medium;
    cursor: pointer;

    @include branded('color', $primaryBranded);

    &:focus,
    &:hover {
        text-decoration: underline;

        @include branded('color', $primaryBranded);
    }
}

// Deprecated
@mixin hvac-button-dark {
    @include hvac-button-colors(
        $white, $primaryLightTint3Branded,
        $white, $primaryLightTint4Branded, $primaryLightBranded,
        $white, $primaryLightTint3Branded, $accentBranded
    );
}

// Deprecated
@mixin hvac-button-primary-dark {
    @include hvac-button-colors(
        $white, $primaryBranded,
        $white, $primaryDarkBranded, $primaryLightBranded,
        $white, $primaryDarkBranded, $accentBranded
    );
}

// Deprecated
@mixin hvac-button-secondary-dark {
    @include hvac-button-colors(
        $primaryExtraLightBranded, $primaryLightTint3Branded,
        $white, $primaryLightTint4Branded, $primaryLightBranded,
        $primaryExtraLightBranded, $primaryLightTint3Branded, $accentBranded
    );
}

@mixin secondary-theme {
    .icp &,
    .totaline & {
        @content;
    }
}
